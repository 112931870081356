'use client';
import DumbLink from '@web/components/DumbLink';
import { FlexBox } from '@web/components/flex-box';
import LoginIcon from '@mui/icons-material/Login';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { usePathname } from 'next/navigation';
import { useState } from 'react';

export default function UserMenu({ user }) {
  const pathname = usePathname();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <>
      {!user && (
        <DumbLink href={`/api/auth/login?returnTo=${pathname}`}>
          <FlexBox alignItems="center" gap={1} color="primary.main">
            <LoginIcon />
            Login
          </FlexBox>
        </DumbLink>
      )}
      {user && (
        <FlexBox alignItems="center" gap={1} sx={{ flexGrow: 0 }}>
          <DumbLink href={`/my/collections`}>
            <FlexBox alignItems="center" color="grey.600">
              <FavoriteBorderIcon fontSize="large" />
            </FlexBox>
          </DumbLink>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt={user.name} src={user.picture} />
          </IconButton>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <FlexBox
              alignItems="center"
              justifyContent="center"
              color="grey.600"
            >
              {user?.nickname || user?.name}
            </FlexBox>
            <Divider />
            <MenuItem onClick={handleCloseUserMenu}>
              <ListItemIcon>
                <FavoriteBorderIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <DumbLink href={`/my/collections`}>My Collections</DumbLink>
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={handleCloseUserMenu}>
              <ListItemIcon>
                <HistoryIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <DumbLink href={`/my/history`}>Browsing History</DumbLink>
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={handleCloseUserMenu}>
              <ListItemIcon>
                <NotificationsNoneIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <DumbLink href={`/my/alerts`}>Price Alerts</DumbLink>
              </ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleCloseUserMenu}>
              <ListItemIcon>
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <DumbLink href={`/api/auth/logout?returnTo=${pathname}`}>
                  Logout
                </DumbLink>
              </ListItemText>
            </MenuItem>
          </Menu>
        </FlexBox>
      )}
    </>
  );
}
