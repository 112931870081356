'use client';

import { Box, Stack, Chip, Typography } from '@mui/material';
import SearchBar from '@web/components/layouts/SearchBar';
import MenuMobile from '@web/components/layouts/MenuMobile';
import UserMenu from '@web/layouts/UserMenu';
import PriceTrendDisplay from '@web/components/PriceTrendDisplay';

interface HeaderNavProps {
  user?: any;
  stats?: {
    priceChangePercent: number;
    totalProducts: number;
    totalOffers: number;
    lastUpdated: Date;
    totalSellers: number;
    history: any[];
  } | null;
}

export default function HeaderNav({ user, stats }: HeaderNavProps) {
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          display: { xs: 'flex', md: 'none' },
          height: 64,
        }}
      >
        <SearchBar isMobile />
        <MenuMobile user={user} />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          display: { xs: 'none', md: 'flex' },
          height: 64,
        }}
      >
        <SearchBar />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {stats && (
            <PriceTrendDisplay
              trend={stats.priceChangePercent}
              history={stats.history}
            />
          )}
          <UserMenu user={user} />
        </Box>
      </Stack>
    </>
  );
}
