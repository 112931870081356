'use client';

import { Box, Chip, Stack, Typography } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { AreaChart, Area, ResponsiveContainer, Tooltip } from 'recharts';
import { format } from 'date-fns';
import DumbLink from '@web/components/DumbLink';

interface PriceTrendDisplayProps {
  trend: number;
  history: any[];
}

export default function PriceTrendDisplay({
  trend,
  history,
}: PriceTrendDisplayProps) {
  const isPositive = trend > 0;
  const formattedTrend = trend.toFixed(4);
  const chartData = history.reverse();

  return (
    <DumbLink href="/analytics/overview" style={{ textDecoration: 'none' }}>
      <Chip
        sx={{
          bgcolor: 'background.paper',
          '&:hover': { bgcolor: 'action.hover' },
          py: 1.5,
          height: 'auto',
        }}
        label={
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack direction="row" alignItems="center" spacing={0.5}>
              {isPositive ? (
                <TrendingUpIcon sx={{ color: 'error.main' }} />
              ) : (
                <TrendingDownIcon sx={{ color: 'success.main' }} />
              )}
              <Typography
                variant="body2"
                sx={{
                  color: isPositive ? 'error.main' : 'success.main',
                  fontWeight: 500,
                }}
              >
                {isPositive ? '+' : ''}
                {formattedTrend}% {isPositive ? 'inflation' : 'deflation'}
              </Typography>
            </Stack>

            {/* Mini Chart */}
            <Box sx={{ width: 60, height: 24 }}>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={chartData}>
                  <defs>
                    <linearGradient
                      id="trendGradient"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop
                        offset="5%"
                        stopColor={isPositive ? '#FF4842' : '#54D62C'}
                        stopOpacity={0.8}
                      />
                      <stop
                        offset="95%"
                        stopColor={isPositive ? '#FF4842' : '#54D62C'}
                        stopOpacity={0}
                      />
                    </linearGradient>
                  </defs>
                  <Tooltip
                    cursor={false}
                    content={({ active, payload }) => {
                      if (active && payload && payload.length) {
                        const value = payload[0].payload.average_price_trend;
                        const timestamp =
                          payload[0].payload.cycle.cycle_timestamp;
                        return (
                          <Box
                            sx={
                              {
                                /* ... */
                              }
                            }
                          >
                            <div>{value.toFixed(2)}%</div>
                            <div style={{ color: '#666', fontSize: 10 }}>
                              {format(new Date(timestamp), 'MMM d, yyyy')}
                            </div>
                          </Box>
                        );
                      }
                      return null;
                    }}
                  />
                  <Area
                    type="monotone"
                    dataKey="average_price_trend"
                    stroke={isPositive ? '#FF4842' : '#54D62C'}
                    fill={`url(#trendGradient)`}
                    dot={false}
                    strokeWidth={1}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>
          </Stack>
        }
      />
    </DumbLink>
  );
}
