'use client';

import { createContext, ReactNode, useCallback, useEffect } from 'react';
import useLocalStorage from '@web/context/localstorage';
import axios from 'axios';
import { usePathname } from 'next/navigation';
import { v4 as uuidv4 } from 'uuid';

const AnalyticsContext = createContext<any>(undefined!);

type Props = {
  children: ReactNode;
};
const USER_ID_KEY = 'bw_user_id';
const isLocal = () => location.hostname === 'localhost';
export const EventsProvider = ({ children }: Props) => {
  const pathname = usePathname();
  const [userId] = useLocalStorage(USER_ID_KEY, '');
  const analytics: any = {};

  useEffect(() => {
    if (!userId) {
      let newUserId = uuidv4();
      localStorage.setItem(USER_ID_KEY, newUserId);
    }
  }, [userId]);

  useEffect(() => {
    if (userId && !isLocal()) {
      axios
        .post('/api/analytics/page', {
          userId,
          path: location.pathname,
          pageUrl: location.href,
          pageTitle: document.title,
        })
        .then();
    }
  }, [pathname, userId]);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
